import * as React from "react"
import ReactMarkdown from "react-markdown"

interface ContentProps {
  content: any
  className: string
}

export function Content({ content, className }: ContentProps) {
  return <ReactMarkdown className={className} source={content} />
}
