import React from "react"

interface Props {
  children: string
}

function PageHeading({ children }: Props) {
  return (
    <h1 className="max-w-lg text-4xl font-bold leading-10 text-light font-display md:text-5xl md:leading-none">
      {children}
    </h1>
  )
}

export default PageHeading
