import React, { ReactElement } from "react"
import { Link } from "gatsby"

interface Props {
  children: string
  url: string
}

export default function CTA({ children, url }: Props): ReactElement {
  function handleClick(name: string) {
    try {
      if (typeof window !== "undefined") {
        window.gtag("event", "click", {
          event_category: "Call to Action",
          event_label: name,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Link
      to={url}
      title="Contacta un asesor"
      onClick={() => handleClick(children)}
      className="inline-block px-6 py-3 text-base font-bold leading-6 text-white transition duration-150 ease-in-out transform bg-orange-500 rounded-sm hover:bg-orange-400 hover:-translate-y-1"
    >
      {children}
    </Link>
  )
}
